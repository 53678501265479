<template>
  <div class="display">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="title">表示設定</div>
      <div class="content">
        <div class="area buttons" v-if="!sortModeFlg">
          <base-sort-button
            class="button"
            :text="'並び替え'"
            @click="setSortMode"
          />
        </div>
        <div class="area buttons" v-else>
          <base-button-small-white class="button" @click="cancelSortMode"
            >キャンセル</base-button-small-white
          >
          <base-button-small-orange
            class="button"
            v-if="lookOnlyFlg === 0"
            @click="updateOrder"
            :disabled="waitFlg"
            >並び順登録</base-button-small-orange
          >
        </div>
        <div class="area list">
          <div class="heading">
            <div class="text">予約の色の優先順位</div>
            <span v-show="sortModeFlg">{{ sortModeMessage }}</span>
            <hint-balloon v-show="!sortModeFlg" :note="note" />
          </div>
          <list-table
            class="color-settings-list"
            :headerData="headers"
            :propertyData="properties"
            :bodyData="displayColorOrder"
            :sortModeFlg="sortModeFlg"
            :showUpperFrame="false"
            @order="changeOrder"
          />
        </div>
        <div class="content-title">タイムテーブルの設定</div>
        <div class="common-setting-base-form">
          <div class="row">
            <text-input-form
              :validationRules="{
                requiredRule: true,
                regexShowId: true,
                greaterThan: 79,
                lessThan: 801
              }"
              :requiredFlg="true"
              :styles="textInputStyles"
              v-model="mixinInputData.timeTableUnitHeight"
              >縦幅(一時間あたり)</text-input-form
            >
          </div>
        </div>
        <div class="decision-button">
          <base-button-medium-orange
            v-if="lookOnlyFlg === 0"
            :disabled="invalid || waitFlg"
            @click="editData"
            >登録</base-button-medium-orange
          >
        </div>
      </div>
      <announce-popup
        v-if="sortPopup.showFlg"
        :type="sortPopup.type"
        :title="sortPopup.title"
        :buttons="sortPopup.buttons"
        @close="closeSortPopup"
        >{{ sortPopup.message }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import BaseSortButton from '@/components/parts/atoms/BaseSortButton'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import ListTable from '@/components/parts/organisms/ListTable.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import HintBalloon from '@/components/parts/atoms/HintBalloon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Display',

  components: {
    BaseSortButton,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    BaseButtonMediumOrange,
    TextInputForm,
    ListTable,
    AnnouncePopup,
    UnsavedLeavePopup,
    HintBalloon,
    ValidationObserver
  },

  mixins: [CheckInputDifference],

  data() {
    return {
      headers: ['標準', '予約の色の優先順位'],
      properties: ['order', 'name'],
      names: {
        reservationColumn: '予約列',
        reservationPurpose: '来院目的',
        staff: 'スタッフ'
      },
      note:
        '予約表の予約アイテムの色の設定です。\n（例）「来院目的」＞「予約列」＞「スタッフ」の場合、\n予約アイテムで来院目的を指定したら来院目的の色で表示します。来院目的を指定しなかったら予約列の色で表示します。\n（注）予約列の色はどの予約アイテムでも指定してあるので、「予約列」より優先度が低いものは表示に影響しません。',
      sortModeFlg: false,
      sortModeMessage:
        'の並び順をドラッグ＆ドロップで変更した後、『並び順登録』ボタンを押して保存して下さい',
      sortPopup: {
        showFlg: false,
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる']
      },
      mixinInputData: { colorOrder: [], timeTableUnitHeight: 0 },
      mixinInitialData: { colorOrder: [], timeTableUnitHeight: 0 },
      waitFlg: false,
      textInputStyles: { width: '208px' }
    }
  },

  computed: {
    ...mapGetters({
      display: 'display/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    colorOrder() {
      return this.display.colorOrder.map((v, i) => {
        return { id: i + 1, order: i + 1, name: this.names[v], data: v }
      })
    },
    displayColorOrder() {
      return this.sortModeFlg ? this.mixinInputData.colorOrder : this.colorOrder
    }
  },

  watch: {
    sortModeFlg: function() {
      this.setColorOrder()
    }
  },

  created() {
    this.setData()
  },

  methods: {
    setData() {
      this.mixinInputData = {
        colorOrder: this.colorOrder,
        timeTableUnitHeight: this.display.timeTableUnitHeight
      }
      this.mixinSetInitialData()
    },
    setColorOrder() {
      this.mixinInputData.colorOrder = this.colorOrder
      this.mixinSetInitialData()
    },
    setSortMode() {
      this.sortModeFlg = true
    },
    cancelSortMode() {
      this.sortModeFlg = false
    },
    changeOrder(data) {
      this.mixinInputData.colorOrder = data.map(v1 =>
        this.mixinInputData.colorOrder.find(v2 => v2.id === v1.id)
      )
    },
    async updateOrder() {
      this.waitFlg = true
      const sendDisplay = {
        ...this.display,
        colorOrder: this.mixinInputData.colorOrder.map(v => v.data)
      }
      const result = await this.$store.dispatch('display/update', sendDisplay)
      if (result === true) {
        this.mixinSetInitialData()
        this.sortPopup.type = 'success'
        this.sortPopup.title = '完了'
        this.sortPopup.message = '並び順を登録しました'
      } else {
        this.sortPopup.type = 'failure'
        this.sortPopup.title = '失敗'
        this.sortPopup.message = '並び順登録に失敗しました'
      }
      this.sortPopup.showFlg = true
      this.waitFlg = false
    },
    async editData() {
      this.waitFlg = true
      const sendDisplay = {
        ...this.display,
        timeTableUnitHeight: this.mixinInputData.timeTableUnitHeight
      }
      const result = await this.$store.dispatch('display/update', sendDisplay)
      if (result === true) {
        this.mixinSetInitialData()
        this.sortPopup.type = 'success'
        this.sortPopup.title = '完了'
        this.sortPopup.message = '表示設定を登録しました'
      } else {
        this.sortPopup.type = 'failure'
        this.sortPopup.title = '失敗'
        this.sortPopup.message = '表示設定登録に失敗しました'
      }
      this.sortPopup.showFlg = true
      this.waitFlg = false
    },
    closeSortPopup() {
      this.sortPopup.showFlg = false
      if (this.sortPopup.type === 'success') {
        this.sortModeFlg = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.display {
  width: 100%;
  min-width: 614px;
  text-align: left;
  > .validation-observer {
    > .title {
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid #{$light-grey};
    }
    > .content {
      margin-top: 20px;
      margin-bottom: 24px;
      > .area {
        > .heading {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 15px;
          font-weight: bold;
        }
        &.buttons {
          display: flex;
          justify-content: flex-end;
          > .button {
            margin-left: 20px;
          }
        }
        &.list {
          margin-top: 26px;
          ::v-deep .list-table .table-body {
            max-height: 560px;
          }
          ::v-deep .list-table-body-row {
            cursor: default;
            &.sort {
              cursor: pointer;
            }
          }
        }
      }
      .content-title {
        margin: 22px 0 30px;
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .common-setting-base-form {
        width: 650px;
        > .row {
          width: 600px;
          margin-bottom: 25px;
        }
      }
      > .decision-button {
        display: flex;
        justify-content: center;
        margin: 60px 0;
      }
    }
  }
}
</style>
