var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"display"},[_c('validation-observer',{staticClass:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"title"},[_vm._v("表示設定")]),_c('div',{staticClass:"content"},[(!_vm.sortModeFlg)?_c('div',{staticClass:"area buttons"},[_c('base-sort-button',{staticClass:"button",attrs:{"text":'並び替え'},on:{"click":_vm.setSortMode}})],1):_c('div',{staticClass:"area buttons"},[_c('base-button-small-white',{staticClass:"button",on:{"click":_vm.cancelSortMode}},[_vm._v("キャンセル")]),(_vm.lookOnlyFlg === 0)?_c('base-button-small-orange',{staticClass:"button",attrs:{"disabled":_vm.waitFlg},on:{"click":_vm.updateOrder}},[_vm._v("並び順登録")]):_vm._e()],1),_c('div',{staticClass:"area list"},[_c('div',{staticClass:"heading"},[_c('div',{staticClass:"text"},[_vm._v("予約の色の優先順位")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.sortModeFlg),expression:"sortModeFlg"}]},[_vm._v(_vm._s(_vm.sortModeMessage))]),_c('hint-balloon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sortModeFlg),expression:"!sortModeFlg"}],attrs:{"note":_vm.note}})],1),_c('list-table',{staticClass:"color-settings-list",attrs:{"headerData":_vm.headers,"propertyData":_vm.properties,"bodyData":_vm.displayColorOrder,"sortModeFlg":_vm.sortModeFlg,"showUpperFrame":false},on:{"order":_vm.changeOrder}})],1),_c('div',{staticClass:"content-title"},[_vm._v("タイムテーブルの設定")]),_c('div',{staticClass:"common-setting-base-form"},[_c('div',{staticClass:"row"},[_c('text-input-form',{attrs:{"validationRules":{
              requiredRule: true,
              regexShowId: true,
              greaterThan: 79,
              lessThan: 801
            },"requiredFlg":true,"styles":_vm.textInputStyles},model:{value:(_vm.mixinInputData.timeTableUnitHeight),callback:function ($$v) {_vm.$set(_vm.mixinInputData, "timeTableUnitHeight", $$v)},expression:"mixinInputData.timeTableUnitHeight"}},[_vm._v("縦幅(一時間あたり)")])],1)]),_c('div',{staticClass:"decision-button"},[(_vm.lookOnlyFlg === 0)?_c('base-button-medium-orange',{attrs:{"disabled":invalid || _vm.waitFlg},on:{"click":_vm.editData}},[_vm._v("登録")]):_vm._e()],1)]),(_vm.sortPopup.showFlg)?_c('announce-popup',{attrs:{"type":_vm.sortPopup.type,"title":_vm.sortPopup.title,"buttons":_vm.sortPopup.buttons},on:{"close":_vm.closeSortPopup}},[_vm._v(_vm._s(_vm.sortPopup.message))]):_vm._e(),_c('unsaved-leave-popup')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }